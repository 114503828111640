import config from '../../config/config';
import { AddressDTO } from '../../dto/address.types';
import {
	CampaignAssignableWorkspaceDTO,
	CampaignDTO,
	CampaignFAQDTO,
	CampaignLeadDTO,
	CampaignListing,
	DeepStatsDTO,
	ShallowStatsDTO,
} from '../../dto/campaign.types';
import { FetchWrapper, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';

export type ScrapeResult = {
	result: {
		title: string;
		description: string;
		image: string;
		services: string;
		companyDescription: string;
		ctas: string[];
	};
	url: string;
	imageUrl?: string;
};

export type CampaignStatistics = {
	stats: {
		shallowStats: ShallowStatsDTO;
		deepStats: DeepStatsDTO | null;
		faqs: CampaignFAQDTO[];
	};
};

export default class CampaignApi {
	static async getCampaign(campaignId: number): Promise<FetchWrapperTypedResponse<CampaignDTO>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}`;

		return FetchWrapper.typedGet<CampaignDTO>(url);
	}

	static async getCampaigns(): Promise<FetchWrapperTypedResponse<{ campaigns: CampaignDTO[] }>> {
		const url = `//${config.hosts.api}/v1/campaign`;

		return FetchWrapper.typedGet<{ campaigns: CampaignDTO[] }>(url);
	}

	static async createCampaign(campaign: CampaignDTO): Promise<FetchWrapperTypedResponse<{ campaign: CampaignDTO }>> {
		const url = `//${config.hosts.api}/v1/campaign`;

		return FetchWrapper.typedPost<{ campaign: CampaignDTO }>(url, {
			body: JSON.stringify({ campaign }),
		});
	}

	static async updateCampaign(campaign: CampaignDTO): Promise<FetchWrapperTypedResponse<CampaignDTO>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaign.id}`;

		return FetchWrapper.typedPut<CampaignDTO>(url, {
			body: JSON.stringify({ campaign }),
		});
	}

	static async deleteCampaign(campaignId: number): Promise<FetchWrapperTypedResponse<{ campaignId: number }>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}`;

		return FetchWrapper.typedDelete<{ campaignId: number }>(url);
	}

	// get campaign statistics
	static async getCampaignStatistics(
		campaignId: number,
		includeTest: boolean = false
	): Promise<FetchWrapperTypedResponse<CampaignStatistics>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/statistics${
			includeTest ? '?includeTest=true' : ''
		}`;

		return FetchWrapper.typedGet<CampaignStatistics>(url);
	}

	// get leads
	static async getLeads(campaignId: number): Promise<FetchWrapperTypedResponse<{ leads: CampaignLeadDTO[] }>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/leads`;

		return FetchWrapper.typedGet<{ leads: CampaignLeadDTO[] }>(url);
	}

	// scrape url
	static async scrapeUrl(url: string): Promise<FetchWrapperTypedResponse<{ jobId: string; success: boolean }>> {
		const fetchUrl = `//${config.hosts.api}/v1/campaign/autofill?url=${encodeURIComponent(url)}`;

		return FetchWrapper.typedGet<{ jobId: string; success: boolean }>(fetchUrl);
	}

	// get assignable workspaces
	static async getAssignableWorkspaces(
		campaignId: number
	): Promise<FetchWrapperTypedResponse<CampaignAssignableWorkspaceDTO[]>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/assignable-workspaces`;

		return FetchWrapper.typedGet<CampaignAssignableWorkspaceDTO[]>(url);
	}

	// get assignable workspaces
	static async emulateAssignment(
		campaignId: number,
		address: AddressDTO
	): Promise<FetchWrapperTypedResponse<({ distanceKm: number } & CampaignAssignableWorkspaceDTO)[]>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/assignable-workspaces/emulate`;

		return FetchWrapper.typedPost<({ distanceKm: number } & CampaignAssignableWorkspaceDTO)[]>(url, {
			body: JSON.stringify({ address }),
		});
	}

	// save chatwidget config
	static async saveChatWidgetConfig(
		campaignId: number,
		chatWidgetConfig: any
	): Promise<FetchWrapperTypedResponse<any>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/chatwidget`;

		return FetchWrapper.typedPut<any>(url, {
			body: JSON.stringify({ chatWidgetConfig }),
		});
	}

	// accept participant
	static async acceptParticipant(
		campaignId: number,
		participantId: number
	): Promise<FetchWrapperTypedResponse<{ success: boolean }>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/participant/${participantId}/accept`;

		return FetchWrapper.typedPut<{ success: boolean }>(url);
	}

	// reject participant
	static async rejectParticipant(
		campaignId: number,
		participantId: number
	): Promise<FetchWrapperTypedResponse<{ success: boolean }>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/participant/${participantId}/reject`;

		return FetchWrapper.typedPut<{ success: boolean }>(url);
	}

	// auto accept
	static async autoAcceptParticipant(
		campaignId: number,
		workspaceId: number
	): Promise<FetchWrapperTypedResponse<{ success: boolean; id: number }>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/participant/${workspaceId}/auto-accept`;

		return FetchWrapper.typedPost<{ success: boolean; id: number }>(url);
	}

	static async sendRequest(campaignId: number): Promise<FetchWrapperTypedResponse<{ success: boolean }>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/request`;

		return FetchWrapper.typedPost<{ success: boolean }>(url);
	}

	static async withdrawRequest(campaignId: number): Promise<FetchWrapperTypedResponse<{ success: boolean }>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/request`;

		return FetchWrapper.typedDelete<{ success: boolean }>(url);
	}

	static async getListings(): Promise<FetchWrapperTypedResponse<{ listings: CampaignListing[] }>> {
		const url = `//${config.hosts.api}/v1/campaign/listings`;

		return FetchWrapper.typedGet<{ listings: CampaignListing[] }>(url);
	}

	// Embeddings
	static async addEmbedding(
		campaignId: number,
		embeddingId: number
	): Promise<FetchWrapperTypedResponse<{ success: boolean }>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/embedding/${embeddingId}`;

		return FetchWrapper.typedPost<{ success: boolean }>(url);
	}

	static async removeEmbedding(
		campaignId: number,
		embeddingId: number
	): Promise<FetchWrapperTypedResponse<{ success: boolean }>> {
		const url = `//${config.hosts.api}/v1/campaign/${campaignId}/embedding/${embeddingId}`;

		return FetchWrapper.typedDelete<{ success: boolean }>(url);
	}
}
